.project-row {
  --bs-gutter-x: 0 !important;
}

.project-container{
  position: relative;
}

.project-container img {
  display: block;
  height: 100%;
  opacity: 1;
}

.project-container:hover .project-overlay {
  top: 80%;
  height: 20%;
  opacity: 1;
}

.project-overlay {
  position: absolute;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 100%;
  height: 0;
  overflow: hidden;
  padding: 20px 25px;
  background: #ffba00;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.project-container img,
.project-container .project-overlay {
  width: 95%;
}

.project-row .project-container .project-overlay h2 {
  color: #222222;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0 0 15px;
  font-size: 15px;
  margin: 0;
  font-weight: 400;
}

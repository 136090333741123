.fv-plugins-message-container {
    margin-top: 0.3rem;

    .fv-help-block {
        color: red;
        font-size: 1rem;
    }
}

.alert {
    color: red;
}